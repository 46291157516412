<template>
    <v-app>
        <v-row justify="center">
        <v-dialog
            v-model="vegetableDialog.dialog"
            persistent
            scrollable
            max-width="900px"
        >
            <v-form
            ref="form"
            lazy-validation
            >
            <v-card>
                <!-- 表单头部 -->
                <v-toolbar dark color="primary" v-lcx-drag>
                <v-toolbar-title>{{ vegetableDialog.title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="vegetableDialog.dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar>

                <v-card-text>
                <v-container style="padding-top: 10px">
                  <v-row>
                    <!-- 商品编码   -->
                    <v-col cols="4" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >商品编码 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="currVegetable.code"
                            autocomplete="off"
                            @change="remoteCode(currVegetable.code)"
                            :rules="[LcxRules.positiveNumber, myRules.remoteCodeValid]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 商品名称   -->
                    <v-col cols="4" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >商品名称 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="currVegetable.name"
                            autocomplete="off"
                            :rules="[LcxRules.required]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 采购价格   -->
                    <v-col cols="4" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >采购价格 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="currVegetable.priceBuy"
                            autocomplete="off"
                            :rules="[LcxRules.float]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 销售价格  -->
                    <v-col cols="4" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >销售价格 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="currVegetable.priceSale"
                            autocomplete="off"
                            :rules="[LcxRules.float]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 单位   -->
                    <v-col cols="4" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >单位 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="currVegetable.unit"
                            autocomplete="off"
                            :rules="[LcxRules.required]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 供应商   -->
                    <v-col cols="4" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >供应商 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <LcxSupplierSelect ref="supplierSelectRef" @changeSupplierId="changeSupplierId"></LcxSupplierSelect>
                        </div>
                    </v-col>

                    <!-- 进货渠道  -->
                    <v-col cols="4" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >进货渠道</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="currVegetable.channel"
                            autocomplete="off"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 生产厂家/产地  -->
                    <v-col cols="4" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >生产厂家/产地</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="currVegetable.origin"
                            autocomplete="off"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 保质期 -->
                    <v-col cols="4" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >保质期</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="currVegetable.expiryDate"
                            autocomplete="off"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!--  规格型号  -->
                    <v-col cols="12" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >规格型号</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="currVegetable.norms"
                            autocomplete="off"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>                 
                  </v-row>
                </v-container>
                </v-card-text>

                <!-- start:按钮区 -->
                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn class="ma-2" color="primary" @click.prevent="vegetable2save()"
                  v-if="vegetableDialog.isAdd"> 保存 </v-btn>

                <v-btn class="ma-2" color="success" @click.prevent="vegetable2saveAndClose()"> 保存并关闭 </v-btn>

                <v-btn
                    color="blue-grey"
                    class="ma-2 white--text"
                    @click="closeDialog()"
                >
                    关闭
                </v-btn>
                </v-card-actions>
                <!-- end:按钮区 -->
            </v-card>
            </v-form>
        </v-dialog>
        </v-row>

        
    </v-app>
</template>

<style lang="scss">
</style>

<script>
let that;
import Vegetable from "@/assets/js/pages/vegetable/vegetable.js";
import LcxRules from "@/assets/js/pages/vegetable/lcx-rule.js";

import LcxSupplierSelect from "@/view/pages/vegetable/include/supplier-select.vue";

export default {
  name: "LcxVegetableAdd",
  data() {
    return {
      currVegetable: {
        id: null,
        code: null,
        name: null, 
        priceBuy: null, 
        priceSale: null, 
        unit: null,
        supplierId: null, 
        norms: null,
        channel: null, 
        origin: null, 
        expiryDate: null
      },
      vegetableDialog: {
        title: "新增商品", 
        addCount: 0,
        dialog: false,
        isAdd: false
      },
      LcxRules,
      myRules: {
        remoteCodeValid: true
      },
    };
  },
  mounted() {
      that = this;
  },
  components: {
    LcxSupplierSelect
  },
  methods: {
    remoteCode(v) {
      that.myRules.remoteCodeValid = false;

      if (!v) {
        that.myRules.remoteCodeValid = '不能为空';
        return
      }

      that.myRules.remoteCodeValid = '等待校验';

      Vegetable.validateCode(that.currVegetable).then(({ data }) => {
        that.myRules.remoteCodeValid = data
          ? true
          : v + " 已经经被占用，不能重复！";
      });
    },
    changeSupplierId: function(v) {
      that.currVegetable.supplierId = v;
    },
    resetVegetable: function () {
      that.currVegetable = {
        id: null,
        code: null,
        name: null, 
        priceBuy: null, 
        priceSale: null, 
        unit: null,
        supplierId: null, 
        norms: null,
        channel: null, 
        origin: null, 
        expiryDate: null
      };
      that.vegetableDialog = {
        title: "新增商品", 
        addCount: 0,
        dialog: false,
        isAdd: false
      };
      if (this.$refs.form && this.$refs.form.resetValidation) {
        this.$refs.form.resetValidation();
      }
    },
    resetVegetableAfterSave: function () {
      that.currVegetable.id = null;
      that.currVegetable.name = null;
      that.currVegetable.code = null;

      if (this.$refs.form && this.$refs.form.resetValidation) {
        this.$refs.form.resetValidation();
      }
      
      that.vegetableDialog.addCount++;
      that.vegetableDialog.title = "已新增商品（" + that.vegetableDialog.addCount + "条）";
    },
    vegetable2save: function () {
      if(!that.$refs.form.validate()){
        return false;
      }
      Vegetable.save(that.currVegetable).then(({ msg }) => {
        // 消息弹框
        window.Toast("success", msg);
        that.resetVegetableAfterSave();
      });
    },
    closeDialog: function() {
      that.vegetableDialog.dialog = false;
      if (that.vegetableDialog.addCount > 0) {
        that.$emit('vegetable2page');
      }
    },
    vegetable2saveAndClose: function () {
      if(!that.$refs.form.validate()){
        return false;
      }
      Vegetable.save(that.currVegetable).then(({ msg }) => {
        // 关闭模式窗口
        that.vegetableDialog.dialog = false;
        // 重新加载页面列表
        that.$emit('vegetable2page');
        // 消息弹框
        window.Toast("success", msg);
      });
    },
    vegetable2initAdd: function () {
      that.resetVegetable();
      this.$nextTick(() => {
        that.$refs.supplierSelectRef.initSupplierId(null);
      });

      // 模式窗口 - 新增初始化
      that.vegetableDialog.dialog = true;
      that.vegetableDialog.title = "新增商品";
      that.vegetableDialog.isAdd = true;
    },
    vegetable2initEdit: function (id) {
      that.resetVegetable();

      Vegetable.findById(id).then(({ data }) => {
        // 模式窗口 - 修改初始化
        that.currVegetable = data;
        this.$nextTick(() => {
          that.$refs.supplierSelectRef.initSupplierId(data.supplierId);
        });

        that.vegetableDialog.dialog = true;
        that.vegetableDialog.title = "修改商品";
        that.vegetableDialog.isAdd = false;
      });
    },
  }
};
</script>