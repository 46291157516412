import ApiService from "@/core/services/api.service";
import Excel from "@/assets/js/pages/vegetable/excel.js";

var Vegetable = {
    list: function(searchData) {
        return ApiService.post("/vegetable/list", searchData);
    },
    save: function(vegetable) {
        return ApiService.post("/vegetable/save", vegetable);
    },
    del: function(id) {
        return ApiService.get("/vegetable/del?id=" + id);
    },
    findById: function(id) {
        return ApiService.get("/vegetable/findById?id=" + id);
    },
    getDataList: function() {
        return ApiService.get("/vegetable/getDataList");
    },
    fileUpload: function(params) {
        return ApiService.post("/vegetable/fileUpload", params);
    },
    exportExcel: function(params) {
        Excel.exportExcel(
            "/vegetable/downLoadExcel", 
            params, 
            "Vegetable.xls");
    },
    validateCode: function(vegetable) {
        return ApiService.post("/vegetable/validate-code", {
            id: vegetable.id,
            code: vegetable.code
        });
    },
}

export default Vegetable;