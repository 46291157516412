<template>
  <div class="upload-btn-box">
    <v-btn
      :loading="loading3"
      :disabled="loading3"
      color="blue-grey"
      class="white--text"
      @click="choiceFile"
    >
      导入Excel
      <v-icon right dark>mdi-cloud-upload</v-icon>
    </v-btn>

    <input 
      ref="filElem" 
      type="file" 
      class="upload-file" 
      accept=".xls,.xlsx,.csv" 
      @change="getFile" />
  </div>
</template>

<style lang="scss">
  .upload-btn-box {
    button {
        margin-right: 10px;
    }
    input[type=file] {
        display: none;
    }
  }
</style>

<script>

export default {
  name: "UploadFile",
  data() {
    return {
      loading3: false,
      fileInfo: null
    };
  },
  components: {
  },
  mounted() {
  },
  methods: {
    choiceFile() {
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
    //   this.$refs.filElem.click() // 也是可以的
    },
    getFile() {
      var that = this;
      this.loading3 = true;
      const inputFile = this.$refs.filElem.files[0];
      if (!inputFile) {
        return;
      }

    //   console.log(inputFile)
    //   inputFile.type
    //   2003
    //   "application/vnd.ms-excel"
    //   2007
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

      let formData = new FormData()
      formData.append('file', inputFile)

      that.$emit('uploadFile', formData, function() {
          that.loading3 = false;
          // console.log(that.$refs.filElem.value)
          that.$refs.filElem.value = null
      });
    }
    // ,
    // uploadSuccess() {
    //     that.loading3 = false;
    // }
  }
};
</script>
