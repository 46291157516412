<template>
    <v-app>
        <v-row justify="center">
        <v-dialog
            v-model="vegetableDialog.dialog"
            persistent
            scrollable
            max-width="1200px"
        >
            <v-form
            ref="form"
            lazy-validation
            >
            <v-card>
                <!-- 表单头部 -->
                <v-toolbar dark color="primary" v-lcx-drag>
                <v-toolbar-title>{{ vegetableDialog.title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="vegetableDialog.dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar>

                <v-card-text ref="vCardText">
                <v-container style="padding-top: 10px">

                  <!-- ----------------------------law 批量添加-start-------------------------------- -->
                  <!-- <v-row>
                    <v-col cols="1">商品编码 *</v-col>
                    <v-col cols="1">商品名称 *</v-col>
                    <v-col cols="1">采购价格 *</v-col>
                    <v-col cols="1">销售价格 *</v-col>
                    <v-col cols="1">单位 *</v-col>
                    <v-col cols="1">供应商 *</v-col>
                    <v-col cols="1">进货渠道</v-col>
                    <v-col cols="2">生产厂家/产地</v-col>
                    <v-col cols="1">保质期</v-col>
                    <v-col cols="1">规格型号</v-col>
                  </v-row> -->
                  <!-- <v-row>
                    <v-col cols="1">
                      <v-textarea label="商品编码" variant="outlined"></v-textarea>
                    </v-col>
                  </v-row> -->

                  <v-row v-for="(item, index) in goodsAddForm.dynamicItem" :key="index" style="border-bottom: 1px solid #ccc;">
                    <!-- 商品编码   -->
                    <v-col cols="2">
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >商品编码 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="item.code"
                            autocomplete="off"
                            @change="remoteCode(item.code, index)"
                            :rules="[LcxRules.positiveNumber, myRules[index].remoteCodeValid]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 商品名称   -->
                    <v-col cols="2">
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >商品名称 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="item.name"
                            autocomplete="off"
                            :rules="[LcxRules.required]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 采购价格   -->
                    <v-col cols="2">
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >采购价格 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="item.priceBuy"
                            autocomplete="off"
                            :rules="[LcxRules.float]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 销售价格  -->
                    <v-col cols="2" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >销售价格 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="item.priceSale"
                            autocomplete="off"
                            :rules="[LcxRules.float]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 单位   -->
                    <v-col cols="2" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >单位 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="item.unit"
                            autocomplete="off"
                            :rules="[LcxRules.required]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 供应商   -->
                    <v-col cols="2" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >供应商 *</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <LcxSupplierSelect ref="supplierSelectRef" @changeSupplierId="changeSupplierId" :index="index"></LcxSupplierSelect>
                        </div>
                    </v-col>

                    <!-- 进货渠道  -->
                    <v-col cols="2" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >进货渠道</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="item.channel"
                            autocomplete="off"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 生产厂家/产地  -->
                    <v-col cols="2" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >生产厂家/产地</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="item.origin"
                            autocomplete="off"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 保质期 -->
                    <v-col cols="2" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >保质期</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="item.expiryDate"
                            autocomplete="off"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!--  规格型号  -->
                    <v-col cols="4" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >规格型号</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="input"
                            v-model="item.norms"
                            autocomplete="off"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>
                    <!-- 删除操作 -->
                    <v-col cols="1" v-if="index!==0">
                      <div class="d-flex justify-content-between mt-n5 pt-10"></div>
                      <div>
                        <el-button type="danger" @click="deleteItem(item,index)" icon="el-icon-remove-outline" circle></el-button>
                      </div>
                    </v-col>
                  </v-row>
          
                  <!-- law 批量添加-end -->
                </v-container>
                </v-card-text>

                <!-- start:按钮区 -->
                <v-card-actions>
                <v-spacer></v-spacer>
                <!-- 批量添加 -->
                <v-btn
                  variant="outlined"
                  size="large"
                  icon
                  color="info"
                  @click="addItem()" 
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
                <!-- <v-btn @click="addItem()" color="blue" prepend-icon="mdi-cloud-upload"></v-btn> -->
                <!-- <el-button type="primary" @click="addUsers('goodsAddForm')" icon="el-icon-position"></el-button> -->

                <v-btn class="ma-2" color="primary" @click.prevent="vegetable2save()"
                  v-if="vegetableDialog.isAdd"> 保存 </v-btn>

                <v-btn
                    color="blue-grey"
                    class="ma-2 white--text"
                    @click="closeDialog()"
                >
                    关闭
                </v-btn>
                </v-card-actions>
                <!-- end:按钮区 -->
            </v-card>
            </v-form>
        </v-dialog>
        </v-row>

        
    </v-app>
</template>

<style lang="scss">
</style>

<script>
let that;
let items = 1;
import Vegetable from "@/assets/js/pages/vegetable/vegetable.js";
import LcxRules from "@/assets/js/pages/vegetable/lcx-rule.js";

import LcxSupplierSelect from "@/view/pages/vegetable/include/supplier-select.vue";

export default {
  name: "LcxVegetableAdd",
  data() {
    return {
      vegetableDialog: {
        title: "批量新增商品", 
        addCount: 0,
        dialog: false,
        isAdd: false
      },
      LcxRules,
      // myRules: {
      //   remoteCodeValid: true
      // },
      myRules: [
        { remoteCodeValid: true }
      ],
      // --------------start-------------------
      goodsAddForm: {
          dynamicItem: [
            {
              code: null, 
              name: null, 
              priceBuy: null, 
              priceSale: null, 
              unit: null,
              supplierId: null, 
              norms: null,
              channel: null, 
              origin: null, 
              expiryDate: null 
            }
          ]
      },
      postItem: []
      // --------------end-------------------
    };
  },
  mounted() {
      that = this;
  },
  components: {
    LcxSupplierSelect
  },
  methods: {
    remoteCode(v, i) {
      // console.log(v)
      that.myRules[i].remoteCodeValid = false;

      if (!v) {
        that.myRules[i].remoteCodeValid = '不能为空';
        return
      }

      that.myRules[i].remoteCodeValid = '等待校验';

      Vegetable.validateCode(that.goodsAddForm.dynamicItem[i]).then(({ data }) => {
        that.myRules[i].remoteCodeValid = data
          ? true
          : v + " 已经经被占用，不能重复！";
      });
    },
    changeSupplierId: function(v, i) {
      
      that.goodsAddForm.dynamicItem[i].supplierId = v;
      // that.currVegetable.supplierId = v;

    },
    resetVegetable: function () {
      this.goodsAddForm.dynamicItem = 
      [
        {
          code: null, 
          name: null, 
          priceBuy: null, 
          priceSale: null, 
          unit: null,
          supplierId: null, 
          norms: null,
          channel: null, 
          origin: null, 
          expiryDate: null 
        }
      ]
      that.vegetableDialog = {
        title: "批量新增商品", 
        addCount: 0,
        dialog: false,
        isAdd: false
      };
      if (this.$refs.form && this.$refs.form.resetValidation) {
        this.$refs.form.resetValidation();
      }
    },
    resetVegetableAfterSave: function () {
      this.$refs.form.resetValidation();
      // if (this.$refs.form && this.$refs.form.resetValidation) {
      //   this.$refs.form.resetValidation();
      // }
      
      // that.vegetableDialog.addCount++;
      // that.vegetableDialog.title = "已新增商品（" + that.vegetableDialog.addCount + "条）";
    },
    vegetable2save: function () {
      // console.log(this.goodsAddForm)
      if(!that.$refs.form.validate()){
        return false;
      }
      // 循环添加
      var goodsArr = that.goodsAddForm.dynamicItem;
      // console.log(goodsArr);
      goodsArr.forEach(el => {
        Vegetable.save(el).then(({ msg }) => {
          // 关闭模式窗口
          that.vegetableDialog.dialog = false;
          // 重新加载页面列表
          that.$emit('vegetable2page');
          // 消息弹框
          window.Toast("success", msg);
          // that.resetGoodsAddForm();
          // that.resetVegetableAfterSave();
          that.resetVegetable();
        });
      });
    },
    closeDialog: function() {
      that.vegetableDialog.dialog = false;
      if (that.vegetableDialog.addCount > 0) {
        that.$emit('vegetable2page');
      }
    },
    vegetable2initAdd: function () {
      // console.log('that.$refs.supplierSelectRef', that.$refs.supplierSelectRef)
      that.resetVegetable();
      // if (that.$refs.supplierSelectRef) {
      //   that.$refs.supplierSelectRef[0].initSupplierId(null);
      // }
      that.$nextTick(() => {
        
        this.goodsAddForm.dynamicItem = [];
        setTimeout(() => {
          this.goodsAddForm.dynamicItem.push({
                code: null, 
                name: null, 
                priceBuy: null, 
                priceSale: null, 
                unit: null,
                supplierId: null, 
                norms: null,
                channel: null, 
                origin: null, 
                expiryDate: null 
              })
        },0)
      
        that.$refs.supplierSelectRef[0].initSupplierId(null);
        // 原生方法清除，暂时没找到好的方法
        // if (document.querySelector('.v-select__selection--comma')) {
        //   document.querySelector('.v-select__selection--comma').innerHTML = '';
        // }
      });

      // 模式窗口 - 新增初始化
      that.vegetableDialog.dialog = true;
      that.vegetableDialog.title = "批量新增商品";
      that.vegetableDialog.isAdd = true;
    },
    // -----------------------start------------------------
    addItem(){
        if(items < 10){
            this.goodsAddForm.dynamicItem.push({
              code: null, 
              name: null, 
              priceBuy: null, 
              priceSale: null, 
              unit: null,
              supplierId: null, 
              norms: null,
              channel: null, 
              origin: null, 
              expiryDate: null 
            })
            this.myRules.push({remoteCodeValid: true })
            items++
            //console.log("items:"+items)
            this.scrollToBottom();
        }else{
            this.$message({
                message:'最多只能一次性添加10条数据！',
                offset:100,
                type:"error"
            });
            return false;
        }
    },
    deleteItem(item,index){
        this.goodsAddForm.dynamicItem.splice(index, 1)
        if(items != 1){
            items--
        }
        this.scrollToBottom();
    },
    addUsers(){
        let j = this.goodsAddForm.dynamicItem.length
        for(var i = 0;i < j ;i++){
            this.postItem.push(this.goodsAddForm.dynamicItem[i])
        }
        // console.log("清空后：this.postItem2:"+JSON.stringify(this.postItem))
    },
    //滚动条保持最底部方法
	  scrollToBottom () {
        this.$nextTick(() => {
            var container = this.$refs.vCardText;
            container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });
        })
    }
    // -----------------------end------------------------

  }
};
</script>