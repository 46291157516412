<template>
    <v-select
      v-model="supplierId"
      :items="supplierList"
      item-text="name"
      item-value="id"
      :rules="[LcxRules.selected]"
      @change="changeSupplierId()"
      outlined
      dense
    >
      <template v-slot:prepend-item>
        <v-list-item
          ripple
        >
          <v-list-item-content>
              <div  class="d-flex justify-content-between mt-n5">
                    <label
                        class="font-size-h6 font-weight-bolder text-dark pt-5"
                        >搜索：</label
                    >
                    <span
                        class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                    >（共：{{ totalItem }}条，搜索到：{{ showItem }}条）</span>
              </div>
              <div >
                    <v-text-field 
                        type="input"
                        v-model="supplierName"
                        autocomplete="off"
                        @change="searchSupplier()"
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
              </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
</template>

<style lang="scss">
</style>

<script>
let that;
import LcxRules from "@/assets/js/pages/vegetable/lcx-rule.js";
import Vegetable from "@/assets/js/pages/vegetable/vegetable.js";

  export default {
    name: "LcxSupplierSelect",
    data: () => ({
      supplierList: [],
      supplierListBak: [],
      supplierId: null,
      supplierName: null,
      LcxRules,
      showItem: 0,
      totalItem: 0
    }),
    props: ['index'],

    computed: {
    },

    mounted() {
      that = this;
      this.getDataList();
    //   console.log("LcxSupplierSelect mounted");
    },
    methods: {
      changeSupplierId: function() {
          that.$emit('changeSupplierId', that.supplierId, that.index);
          // that.$emit('changeSupplierId', that.supplierId);

      },
      initSupplierId: function(v) {
          that.supplierId = v;
      },
      getDataList: function () {
        Vegetable.getDataList().then(({ data }) => {
            that.supplierList = data;
            that.supplierListBak = data;
            that.totalItem = data.length;
            that.showItem = data.length;
        });
      },
      searchSupplier () {
        this.$nextTick(() => {
            that.showItem = 0;
            that.totalItem = 0;
            if (that.supplierName == "") {
                that.showAllList();
            } else {
                that.changeList();
            }
        })
      },
      showAllList: function() {
        for (var item of that.supplierListBak) {
            item.show = true;
            that.showItem += 1;
            that.totalItem += 1;
        }
        that.supplierList = that.supplierListBak.filter(e => {
          return e.show;
        });
      },
      changeList: function() {
        for (var item of that.supplierListBak) {
            if (item.name.indexOf(that.supplierName) != -1) {
                item.show = true;
                that.showItem += 1;
            } else {
                item.show = false;
            }
            that.totalItem += 1;
        }
        that.supplierList = that.supplierListBak.filter(e => {
          return e.show;
        });
      },
    }
  }
</script>